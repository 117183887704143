import React, { useState, useEffect } from 'react'
import { Box, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import Button from '../../common/button.component'
import MaskedTextField from '../../common/MaskedTextField.component'
import AutocompleteSelect from '../../common/AutocompleteSelect.component'
import Text from '../../common/text.component'
import RadioButtonsGroup from '../../common/RadioButtonsGroup.component'
import { calculateKms } from '../../common/quotationHelper'

const LABEL_MARCA = 'Marca'
const LABEL_MODELO = 'Modelo'
const LABEL_ANIO = 'Año'
const LABEL_VERSION = 'Versión'
const LABEL_KMS_OPTIONAL = 'Kilometraje (opcional)';
const LABEL_KMS = 'Kilometraje';
const KMS_MASK = new Array(9).fill(/\d/)

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    paddingBottom: '0.5rem',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      '& button': {
        width: '50%',
      },
    },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0.5rem',
    [theme.breakpoints.up('md')]: {
      width: '80%',
      marginRight: 'auto',
    },
  },
  yearAndModelContainer: {
    display: 'flex',
    gap: '1.5rem',
  },
  containerWidth: {
    width: '100%',
    maxWidth: '100%',
  },
  inputKilometraje: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grayScale.g200,
      },
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.grayScale.g400,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  radioButtonContainer: {
    paddingTop: '1rem',
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  buttonCotizar: {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  errorSelect: {
    '& .MuiSelect-icon': {
      color: theme.palette.error.main,
    },
  },
}));

const MESSAGE_ERROR_BRAND = 'Contanos cuál es la marca.'
const MESSAGE_ERROR_MODEL = 'Contanos qué modelo es.'
const MESSAGE_ERROR_YEAR = 'Contanos de qué año es.'
const MESSAGE_ERROR_VERSION = 'Contanos cuál es la versión.'
const MESSAGE_ERROR_KM = 'Contanos cuántos kilómetros tiene.';

const labelExcelente = {
  title: '¡En muy buen estado! (Excelente)',
  label: 'Está en excelentes condiciones mecánicas, exteriores e interiores. No necesita reacondicionamiento.'
};
const labelBueno = {
  title: '¡Está bien! (Bueno)',
  label: 'Tiene el desgaste normal pero sin problemas mecánicos o estéticos importantes. Sí puede requerir un mínimo reacondicionamiento.'
};
const labelRegular = {
  title: 'Tiene su historia (Regular)',
  label: 'Con varios problemas mecánicos y/o estéticos. Sí necesita de grandes reparaciones.'
};

export default function QuotationForm({
  horizontalForm,
  buttonText = 'Cotizar',
  filters,
  brands = [],
  models = [],
  years = [],
  versions = [],
  loadingBrands = false,
  loadingModels = false,
  loadingYears = false,
  loadingVersiones = false,
  onGetQuote = () => {},
  onSelectBrand = () => {},
  onSelectModel = () => {},
  onSelectYear = () => {},
  onSelectVersion = () => {},
  onChangeKm = () => {},
  previousQuote,
  addToBudget,
}) {
  const classes = useStyles();
  const [kms, setKms] = useState(filters?.kms);

  useEffect(()=>{
    if(!filters?.kms){
      setKms('');
    }
  },[filters?.kms]);


  const REGULAR_STATE = '5';
  const [estado, setEstado] = useState(filters?.estado || REGULAR_STATE);
  const gridWidth = horizontalForm ? 6 : 12
  const [errors, setErrors] = useState(addToBudget ? {
    brand: false,
    model: false,
    year: false,
    version: false,
    kilometros: false,
  } : {
    brand: false,
    model: false,
    year: false,
    version: false,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleScroll = (id) => {
    if(!isMobile) return;
    const element = document.getElementById(id);
    const rect = element.getBoundingClientRect();
    //Sin el timeout, no da tiempo a calcular el scroll excluyendo al teclado, y scrollea de mas.
    setTimeout(()=>{window.scrollBy({ left: 0, top: rect.top - rect.height * 2 -160, behaviour: 'smooth' });},500);
  };

  const LABEL_ESTADO_EXCELENTE = (
    <Box marginBottom="1rem">
      <Box>
        <Text medium bold>
          {labelExcelente.title}
        </Text>
      </Box>
      <Text little light>
        {labelExcelente.label}
      </Text>
    </Box>
  )
  const LABEL_ESTADO_BUENO = (
    <Box marginBottom="1rem">
      <Box>
        <Text medium bold>
          {labelBueno.title}
        </Text>
      </Box>
      <Text little light>
        {labelBueno.label}
      </Text>
    </Box>
  )
  const LABEL_ESTADO_REGULAR = (
    <Box marginBottom="1rem">
      <Box>
        <Text medium bold>
          {labelRegular.title}
        </Text>
      </Box>
      <Text little light>
        {labelRegular.label}
      </Text>
    </Box>
  )

  const titleSecondStep = (
    <Text medium bold>
      En qué condiciones está
    </Text>
  )

  useEffect(() => {
    if(filters.brand && filters.brand.length > 1){
      setErrors({ ...errors, brand: false });
    }
    if(filters.model && filters.model.length > 1){
      setErrors({ ...errors, model: false });
    }
    if(filters.year && filters.year.length > 1){
      setErrors({ ...errors, year: false });
    }
    if(filters.version && filters.version.length > 1){
      setErrors({ ...errors, version: false });
    }
    if (addToBudget) {
      if((kms && kms.length > 1)){
        setErrors({ ...errors, kilometros: false });
      }
    }
  }, [onSelectBrand, onSelectModel, onSelectYear, onSelectVersion, onChangeKm]);

  const validationsForm = (form, kilometros) => {
    if(addToBudget) {
      if (!form.brand) {
        setErrors({ ...errors, brand: !form.brand, kilometros: !kilometros });
        return
      }
      if (!form.model) {
        setErrors({ ...errors, model: !form.model, kilometros: !kilometros });
        return
      }
      if (!form.year) {
        setErrors({ ...errors, year: !form.year, kilometros: !kilometros });
        return
      }
      if (!form.version) {
        setErrors({ ...errors, version: !form.version, kilometros: !kilometros });
        return;
      }
      if (!kilometros) {
        setErrors({ ...errors, kilometros: true });
        return;
      }

    } else {
      if (!form.brand) {
        setErrors({ ...errors, brand: !form.brand });
        return
      }
      if (!form.model) {
        setErrors({ ...errors, model: !form.model });
        return
      }
      if (!form.year) {
        setErrors({ ...errors, year: !form.year });
        return
      }
      if (!form.version) {
        setErrors({ ...errors, version: !form.version });
        return
      }
    }

    onGetQuote({
      interior: parseInt(estado),
      kms: calculateKms(kms, filters.year, filters.tipo_cotizador),
      isKmsCalculated: !kms,
      vehicle: {
        brand: filters.brandId,
        brandName: filters.brandName,
        model: filters.modelId,
        modelName: filters.modelName,
        version: filters.version,
        versionName: filters.versionName,
        year: filters.year,
        tipo_cotizador: filters.tipo_cotizador,
      },
    });
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={gridWidth}>
        <Box
          className={`${classes.mainContainer} ${
            !horizontalForm && classes.containerWidth
          }`}
        >
          <AutocompleteSelect
            id="select-marca"
            onFocus={()=>handleScroll('select-marca')}
            getOptionLabel={(option) => option.label}
            label={LABEL_MARCA}
            loading={loadingBrands}
            className={errors.brand && classes.errorSelect}
            receivedValue={filters.brand}
            onChange={onSelectBrand}
            options={brands.map((b) => ({
              label: b.name,
              value: b,
              key: b.id[0],
            }))}
            error={errors.brand}
            helperText={MESSAGE_ERROR_BRAND}
          />
          <Box className={`${classes.yearAndModelContainer}`}>
            <AutocompleteSelect
              id="select-modelo"
              onFocus={()=>handleScroll('select-modelo')}
              getOptionLabel={(option) => option.label}
              label={LABEL_MODELO}
              loading={loadingModels}
              className={errors.model && classes.errorSelect}
              disabled={!filters.brand}
              receivedValue={filters.model}
              onChange={onSelectModel}
              options={models.map((mo) => ({
                label: mo.name,
                value: mo,
                key: mo.id[0],
              }))}
              error={errors.model}
              helperText={MESSAGE_ERROR_MODEL}
            />
            <AutocompleteSelect
              id="select-anio"
              onFocus={()=>handleScroll('select-anio')}
              getOptionLabel={(option) => option.label}
              label={LABEL_ANIO}
              loading={loadingYears}
              className={errors.year && classes.errorSelect}
              receivedValue={filters.year}
              disabled={!filters.model}
              type="number"
              onChange={onSelectYear}
              options={years.map((a) => ({
                label: a.label,
                value: a.year,
              }))}
              error={errors.year}
              helperText={MESSAGE_ERROR_YEAR}
            />
          </Box>
          <AutocompleteSelect
            id="select-version"
            onFocus={()=>handleScroll('select-version')}
            getOptionLabel={(option) => option.label}
            label={LABEL_VERSION}
            loading={loadingVersiones}
            className={errors.version && classes.errorSelect}
            receivedValue={filters.version}
            disabled={!filters.year}
            onChange={onSelectVersion}
            options={versions.map((v) => ({
              label: v.name,
              value: v,
              key: v.id,
            }))}
            error={errors.version}
            helperText={MESSAGE_ERROR_VERSION}
          />
          <MaskedTextField
            data-test-id="input-kms"
            id="input-kms"
            onClick={()=>handleScroll('input-kms')}
            className={classes.inputKilometraje}
            label={addToBudget ? LABEL_KMS : LABEL_KMS_OPTIONAL}
            value={kms}
            mask={KMS_MASK}
            onChange={(evt) => {
              const newKms = evt.target.value;
              onChangeKm(newKms);
              setKms(newKms);
            }}
            variant="outlined"
            type="number"
            error={addToBudget ? errors.kilometros : false}
            onBlur={()=> {
              if(!kms || kms.length < 2) setErrors({ ...errors,kilometros: true });
            }
            }
            helperText={addToBudget ? errors.kilometros && MESSAGE_ERROR_KM : false }
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={gridWidth}>
        <Box
          display="flex"
          className={!horizontalForm && classes.containerWidth}
          flexDirection="column"
          paddingTop="0.5rem"
        >
          { addToBudget &&
          <>
            {titleSecondStep}
            <Box className={classes.radioButtonContainer}>
              <RadioButtonsGroup
                name="interior"
                value={estado}
                onChange={(evt, value) => {
                  setEstado(value)
                }}
                defaultValue={REGULAR_STATE}
                options={[
                  {
                    label: LABEL_ESTADO_EXCELENTE,
                    value: '10',
                  },
                  {
                    label: LABEL_ESTADO_BUENO,
                    value: '5',
                  },
                  {
                    label: LABEL_ESTADO_REGULAR,
                    value: '1',
                  },
                ]}
              />
            </Box>
          </>
          }
        </Box>
        <Box display="flex" className={classes.buttonContainer}>
          <Button
            primary
            data-test-id="boton-cotizar"
            style={ { width:'auto' } }
            onClick={() => validationsForm(filters, kms)}
          >
            {buttonText}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
